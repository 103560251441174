<h1 cds-text="title">USS Settings</h1>
<form clrForm [formGroup]="settingsForm" [clrLayout]="'vertical'">
  <h3 class="settings-header">General Settings</h3>
  <clr-input-container>
    <label class="required-custom">USS Name</label>
    <input autocomplete="off" clrInput placeholder="USS" formControlName="USS_NAME"/>
  </clr-input-container>
  <error-container [control]="settingsForm.controls.USS_NAME">
    <error-message [has]="'required'">USS Name is required.</error-message>
    <error-message [has]="'maxlength'">USS Name must be less that {{maxUssNameLength}} characters.</error-message>
  </error-container>

  <h3 class="settings-header">Map Settings</h3>
  <ax-map-config formControlName="MAP_CONFIG"></ax-map-config>

  <h3 class="settings-header">Advanced</h3>
  <cds-accordion>
    <cds-accordion-panel [expanded]="experimentalExpanded">
      <cds-accordion-header (click)="experimentalExpanded = !experimentalExpanded">Experimental Settings
      </cds-accordion-header>
      <cds-accordion-content>
        <form clrForm formGroupName="EXPERIMENTAL_SETTINGS">
          <clr-checkbox-container>
            <clr-checkbox-wrapper>
              <label>Enable Dashboard Support</label>
              <input autocomplete="off" type="checkbox" clrCheckbox formControlName="enableDashboardSupport"/>
            </clr-checkbox-wrapper>
            <clr-control-helper>If checked, experimental dashboard support will be enabled.</clr-control-helper>
          </clr-checkbox-container>
<!--          <clr-checkbox-container>-->
<!--            <clr-checkbox-wrapper>-->
<!--              <label>Enable Federation Support</label>-->
<!--              <input autocomplete="off" type="checkbox" clrCheckbox formControlName="enableFederationSupport"/>-->
<!--            </clr-checkbox-wrapper>-->
<!--            <clr-control-helper>If checked, experimental federation support will be enabled.</clr-control-helper>-->
<!--          </clr-checkbox-container>-->
<!--          <clr-checkbox-container>-->
<!--            <clr-checkbox-wrapper>-->
<!--              <label>Enable Direct Messaging Support</label>-->
<!--              <input autocomplete="off" type="checkbox" clrCheckbox formControlName="enableDirectMessagingSupport"/>-->
<!--            </clr-checkbox-wrapper>-->
<!--            <clr-control-helper>If checked, experimental direct messaging support will be enabled.</clr-control-helper>-->
<!--          </clr-checkbox-container>-->
        </form>
<!--        <div *ngIf="settingsForm.controls.EXPERIMENTAL_SETTINGS.value.enableFederationSupport">-->
<!--          <h3 class="settings-header">Federation Settings</h3>-->
<!--          <h4 class="settings-header">Known Federation Domains</h4>-->
<!--          <edit-federation-domain-list formControlName="KNOWN_FEDERATION_DOMAINS"></edit-federation-domain-list>-->
<!--          <h4 class="settings-header">Default Federation Policy</h4>-->
<!--          <edit-federation-policy [domains]="knownFederationDomains"-->
<!--                                  formControlName="DEFAULT_FEDERATION_POLICY"></edit-federation-policy>-->
<!--        </div>-->
      </cds-accordion-content>
    </cds-accordion-panel>
    <cds-accordion-panel [expanded]="laancExpanded">
      <cds-accordion-header (click)="laancExpanded = !laancExpanded">LAANC</cds-accordion-header>
      <cds-accordion-content>
        <span><strong>LAANC Enabled:</strong> {{settings.laanc?.enabled ? 'Yes' : 'No'}}</span>
      </cds-accordion-content>
    </cds-accordion-panel>
  </cds-accordion>

  <clr-alerts>
    <clr-alert *ngIf="saveSuccess === false" [clrAlertType]="'danger'" [clrAlertClosable]="false">
      <clr-alert-item>
        <span class="alert-text">Failed to save settings</span>
      </clr-alert-item>
    </clr-alert>
    <clr-alert *ngIf="!!saveSuccess" [clrAlertType]="'success'" [clrAlertClosable]="false">
      <clr-alert-item>
        <span class="alert-text">Settings saved successfully</span>
      </clr-alert-item>
    </clr-alert>
  </clr-alerts>
  <button class="btn btn-primary btn-save-settings" [disabled]="settingsForm.invalid" (click)="saveForm()">Save</button>
</form>

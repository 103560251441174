import {Component, OnDestroy, OnInit} from '@angular/core';
import {ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {DEFAULT_EXPERIMENTAL_SETTINGS, SettingsService, UssSettings} from '../shared/services/settings.service';
import {Subscription} from 'rxjs';
import {MapConfig} from '@ax/ax-angular-map-common';
import {IFederationDomain} from '../shared/model/FederationPolicy';
import {ClrAlertModule, ClrAlerts, ClrCheckboxModule, ClrControl, ClrInputModule} from "@clr/angular";
import {CdsAccordionModule} from "@cds/angular";
import {AxAngularMapAdminModule} from "@ax/ax-angular-map-admin";
import {ComponentsModule} from "../shared/components/components.module";
import {NgIf} from "@angular/common";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  standalone: true,
  imports: [
    ClrAlertModule,
    CdsAccordionModule,
    ClrInputModule,
    ClrCheckboxModule,
    AxAngularMapAdminModule,
    ComponentsModule,
    ReactiveFormsModule,
    NgIf
  ]
})
export class SettingsComponent implements OnInit, OnDestroy {
  settingsForm: UntypedFormGroup;
  maxUssNameLength = 80;
  maxClassificationLength = 20;
  experimentalExpanded = false;
  laancExpanded = false;
  saveSuccess: boolean = undefined;

  settings: UssSettings = {
    title: '',
    classificationLevel: '',
    mapConfig: new MapConfig([], null, null, null, null),
    knownFederationDomains: [],
    defaultFederationPolicy: {
      allowAll: false,
      domains: []
    },
    experimentalSettings: DEFAULT_EXPERIMENTAL_SETTINGS,
    laanc: {
      enabled: false
    }
  };

  private updateSub: Subscription;
  private rawSettingsSub: Subscription;

  get knownFederationDomains(): IFederationDomain[] {
    return this.settingsForm?.controls.KNOWN_FEDERATION_DOMAINS?.value ?? [];
  }

  constructor(private envService: SettingsService) {
    this.settingsForm = new UntypedFormGroup({
      /* eslint-disable @typescript-eslint/naming-convention*/
      USS_NAME: new UntypedFormControl('', [Validators.required, Validators.minLength(1), Validators.maxLength(this.maxUssNameLength)]),
      CLASSIFICATION: new UntypedFormControl('', [Validators.minLength(1), Validators.maxLength(this.maxClassificationLength)]),
      MAP_CONFIG: new UntypedFormControl(new MapConfig([], null, null, null, null), [Validators.required]),
      KNOWN_FEDERATION_DOMAINS: new UntypedFormControl([], []),
      DEFAULT_FEDERATION_POLICY: new UntypedFormControl(null, [Validators.required]),
      EXPERIMENTAL_SETTINGS: new UntypedFormGroup({
        enableDashboardSupport: new UntypedFormControl(DEFAULT_EXPERIMENTAL_SETTINGS.enableDashboardSupport, []),
        enableFederationSupport: new UntypedFormControl(DEFAULT_EXPERIMENTAL_SETTINGS.enableFederationSupport, []),
        enableDirectMessagingSupport: new UntypedFormControl(DEFAULT_EXPERIMENTAL_SETTINGS.enableDirectMessagingSupport, [])
      })
      /* eslint-enable @typescript-eslint/naming-convention*/
    });
  }

  ngOnInit(): void {
    this.rawSettingsSub = this.envService.getRawSettings().subscribe(settings => {
      this.settings = settings;

      if (settings.title) {
        this.settingsForm.controls.USS_NAME.setValue(settings.title);
      }
      if (settings.classificationLevel) {
        this.settingsForm.controls.CLASSIFICATION.setValue(settings.classificationLevel);
      }

      if (settings.mapConfig) {
        this.settingsForm.controls.MAP_CONFIG.setValue(settings.mapConfig);
      }

      if (settings.knownFederationDomains) {
        this.settingsForm.controls.KNOWN_FEDERATION_DOMAINS.setValue(settings.knownFederationDomains);
      }

      if (settings.defaultFederationPolicy) {
        this.settingsForm.controls.DEFAULT_FEDERATION_POLICY.setValue(settings.defaultFederationPolicy);
      }

      if (settings.experimentalSettings) {
        this.settingsForm.controls.EXPERIMENTAL_SETTINGS.setValue(settings.experimentalSettings);
      }

    });
  }

  saveForm() {
    const newSettings: UssSettings = {
      title: this.settingsForm.controls.USS_NAME.value,
      classificationLevel: this.settingsForm.controls.CLASSIFICATION.value,
      mapConfig: this.settingsForm.controls.MAP_CONFIG.value,
      knownFederationDomains: this.settingsForm.controls.KNOWN_FEDERATION_DOMAINS.value,
      defaultFederationPolicy: this.settingsForm.controls.DEFAULT_FEDERATION_POLICY.value,
      experimentalSettings: this.settingsForm.controls.EXPERIMENTAL_SETTINGS.value
    };
    this.updateSub = this.envService.updateSettings(newSettings).subscribe(() => {
      this.envService.refreshSettings();
      this.saveSuccess = true;
      setTimeout(() => {
        this.saveSuccess = undefined;
      }, 5000);
    }, () => {
      this.saveSuccess = false;
    });
  }

  ngOnDestroy(): void {
    this.updateSub?.unsubscribe();
    this.rawSettingsSub?.unsubscribe();
  }

}
